import React from 'react';
import './terms-of-service-description.css';

// components


class TermsOfServiceDescription extends React.Component{
    
    render(){
        return (<>
               <div className="terms-of-service-description">
                   <strong>PLEASE READ THIS AGREEMENT CAREFULLY</strong>
                   <h1>Terms of Service</h1>
                   <div className="divider"></div>
                   <p>This website (https://tauruscapital.biz/w/), is owned and operated by Taurus Capital, a limited liability company (such entity, together with its subsidiaries, being referred to hereafter as “Bakkt”). These Terms of Use (“Agreement”), apply to any and all websites operated by Taurus Capital®. This Agreement is a legal contract between you and Taurus Capital and applies to your use of the Website, including any information and materials therein and any software that Taurus Capital makes available that allows you to access the Website from a mobile device (“App”). Unless otherwise noted, all references to “Website” shall include any App and any information or materials available via the Website or an App. By accessing all or any part of this Website, you fully accept and agree to comply with all of the terms and conditions set out in this Agreement. You may not use this Website except as expressly provided in this Agreement. If you do not agree to this Agreement, you are not authorized to access and use this Website and should exit immediately.</p>
               </div>
        </>);
    }
}

export default TermsOfServiceDescription;