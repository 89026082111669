import React from "react";
import PackagesHeader from "./package-components/packages-header/packages-header";
import PackageInvestment from "./package-components/packages-investment/packages-investment";
import "./packages-screen.css";
 
class PackageScreen extends React.Component {
  render() {
    return (
      <>
        <div className="sub-main">
          <PackagesHeader />
          <PackageInvestment />
        </div>
      </>
    );
  }
}

export default PackageScreen;
