import React from 'react';
import './contact-info.css';

// assets

class ContactInfo extends React.Component{
  
    render(){
        return (<>
              <div className="contact-info">
                  <h1>CONTACT</h1>
                  <div className="divider"></div>
                  <p>Email: support@tauruscapital.biz</p>
                 <p>Phone: <a href='tel:+13057777852'> + 1 305-777-7852</a></p>
              </div>
        </>);
    }
}

export default ContactInfo;