import React from "react";
 
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import Pulse from "react-reveal/Pulse";

import "./home-header.css";

 
class HomeHeader extends React.Component {
  render() {
    return (
      <>
        <div className="sub-main__header">
          <Zoom delay={200}>
            <h1 className="sub-main__header-text">
              Trade and invest in top stocks and cryptocurrencys with us.
            </h1>
          </Zoom>

          <h2 className="sub-main__header-text sub-main__header-text--smaller">
            investing in the financial markets has never been easier with Taurus
            Capital
          </h2>
          <Zoom delay={500}>
            <h3 className="sub-main__header-text sub-main__header-text--smallest main-color-text">
              we help investors reap huge returns in the financial markets and
              cryptocurrency trading
            </h3>
          </Zoom>

          <div className="sub-main__header-buttons">
            <Fade delay={1000}>
              <Pulse delay={1500}>
                <a
                  href="https://app.tauruscapital.biz/register"
                  className="sub-main__header-button"
                >
                  GET STARTED
                </a>
                <a
                  href="https://app.tauruscapital.biz/login"
                  className="sub-main__header-button"
                >
                  LOG IN
                </a>
              </Pulse>
            </Fade>
          </div>
        </div>
      </>
    );
  }
}

export default HomeHeader;
