import React from "react";
import "./home-get-started-button.css";
import Tada from "react-reveal/Tada";

class HomeGetStartedButton extends React.Component {
  handleClick = () => {
    window.location.href = "https://app.tauruscapital.biz/register";
  };

  render() {
    return (
      <>
        <div
          className={
            this.props.align === "Center" ? "get-started-c" : "get-started"
          }
        >
          <div
            className={
              this.props.align === "Center"
                ? "get-started-c__text"
                : "get-started__text"
            }
          >
            <strong>READY TO START GROWING WITH US?</strong>
          </div>
          <div
            className={
              this.props.align === "Center"
                ? "get-started-c__button"
                : "get-started__button"
            }
          >
            {" "}
            <Tada delay={1000}>
              {" "}
              <button onClick={() => this.handleClick()}>Get Started</button>
            </Tada>
          </div>
        </div>
      </>
    );
  }
}

export default HomeGetStartedButton;
