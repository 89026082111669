import React from 'react';
import AboutUsFirst from './about-us-components/about-us-first/about-us-first';
import AboutUsHeader from './about-us-components/about-us-header/about-us-header';
import AboutUsSecond from './about-us-components/about-us-second/about-us-second';
import AboutUsThird from './about-us-components/about-us-third/about-us-third';
import AboutUsOfferings from './about-us-components/about-us-offerings/about-us-offerings';
import AboutUsOurServices from './about-us-components/about-us-our-services/about-us-our-services';

import './about-us-screen.css';

// components



class AboutUsScreen extends React.Component{
    
    render(){
        return (<>
               <div className="sub-main">

                  <AboutUsHeader/>
                  <AboutUsFirst/>
                  <AboutUsSecond/>
                  <AboutUsThird/>
                  <AboutUsOfferings/>
                  <AboutUsOurServices/>
               </div>
        </>);
    }
}

export default AboutUsScreen;