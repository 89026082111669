import React from "react";
import "./referral-program-partners.css";

// assets
import img1 from "../../images/usted.png";
import img2 from "../../images/36.png";
import img3 from "../../../../../assets/images/simple-images/Maching-bonus.png";

class ReferralProgramPartners extends React.Component {
  render() {
    return (
      <>
        <div className="referral-program-partners">
          <div className="referral-program-partners__title">
            <h1>Referral Program</h1>
            <p>
              Each referred friend must be new to our program and successfully
              complete account sign-up using the referral link.
            </p>
            <strong>Our referral system is in 7 diferent bonus:</strong>
          </div>

          <div className="referral-program-partners__content">
            <div className="referral-program-partners__section">
              <h1>DIRECT BONUS</h1>
              <div className="divider"></div>
              <h4>The first bonus is in two levels</h4>
              <p>
                In the first level during the 45 days, you immediately earn a
                commission of 20% for each of the packages activated by your
                direct line, from the day 46 the Company set you 12%.
              </p>
              <p>
                For the second level, you earn a 5% commission for each package
                purchased by their downlines (the new people referred by your
                direct lines).
              </p>
              <p>
                Let’s say you start with the $1000 package and decided to refer
                two of your friends (Diego and Rafael). You will get 20% of
                their investments each. That means if Diego invests $1000, you
                get $200 – same with Rafael.
              </p>
              <h4>Second Level</h4>
              <p>
                When your direct line/referrals (Diego and Rafael) decide to
                refer 2 people each, you’ll get 5% of their investments (the 4
                new people referred by both Diego and Rafael).
              </p>
              <p>
                That means if you refer 5 people directly, you get 20% of their
                investments and 5% of 25 people indirectly. That’s absolutely
                amazing.
              </p>
                    
              <img style={{width: '40rem', padding: '2rem'}} src={img2} alt="" />
              
              <h1>MATCHING BONUS</h1>
              <div className="divider"></div>
              <h4>
                You earn commisions 10% to 50% of the binary earnings of 3
                generations
              </h4>
              <p>Medium – First generation 10%</p>
              <p>Large – First, second and third generation 10%</p>
              <p>
                Exclusive – First and second generation 10%, third generation
                15%
              </p>
              <p>Pro – First generation 10%, second and third generation 15%</p>
              <p>
                Infinity – First generation 10%, second and third generation 20%
              </p>
              <p>Large – Binary Percentage 13%</p>
              <p>Exclusive – Binary percentage 15%</p>
              <p>Pro – Binary percentage 20%</p>
              <p>Infinity – Binary Percentage 25%</p>
            </div>
            <div className="referral-program-partners__section">
              <img style={{ marginTop: "1rem" }} src={img1} alt="" />

              <h1 style={{ marginTop: "10rem" }}>BINARY BONUS</h1>
              <div className="divider"></div>

              <p>
                You earn comissions 5% to 25% of the volume points generated by
                your payline each week.
              </p>
              <h4>The binary percentage according to your investment</h4>
              <p>Start – Binary percentage 5%</p>
              <p>Lite – Binary Percentage 10%</p>
              <p>Medium – Binary percentage 12%</p>
              <p>Large – Binary Percentage 13%</p>
              <p>Exclusive – Binary percentage 15%</p>
              <p>Pro – Binary percentage 20%</p>
              <p>Infinity – Binary Percentage 25%</p>

              <img style={{ marginTop: "10rem" }} src={img3} alt="" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ReferralProgramPartners;
