import React from 'react';
import './home-road-map.css';

// assets 
import road_img from '../../../../../assets/images/simple-images/roadmap-1.jpg';

class HomeRoadMap extends React.Component{
    
    render(){
        return (<>
            <div className="road-map-main">
                <h1>ROAD MAP</h1>
                <img src={road_img} alt=""/>
            </div>
        </>);
    }
}

export default HomeRoadMap;