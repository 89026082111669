import React from "react";
import "./home-why-choose-us.css";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
// assets
import about_image from "../../../../../assets/images/simple-images/por-que-peferirnos-600-x-800-1.png";

class HomeWhyChooseUs extends React.Component {
  render() {
    return (
      <>
        <div className="why-choose-us-main">
          <div className="why-choose-us-main__content">
            <Fade bottom duration={700}>
              <div className="why-choose-us-main__content-info">
                <h1>WHY CHOOSE US</h1>
                <div className="why-choose-us-main__deco-bar main-color-background"></div>
                <p>
                  At Taurus capital each partner is important, investing in
                  digital markets is very easy with us. <br />
                  <br /> We have a unique trading strategy that combines the
                  guarantee of Crypto-mining with the best Traders in the world.{" "}
                  <br />
                  <br /> Because our servers are mining the most profitable
                  cryptocurrencies on the market, we can obtain them between 10%
                  and 30% below their price and market them with a guaranteed
                  profitability 365 days a year. <br /> <br /> While our traders
                  receive a part of the profits obtained from mining to carry
                  out the best operations and take advantage of market movements
                  to gain both on the rise and the fall. We are using new
                  technologies to build a radically better financial system that
                  enables us to support consistent profitability for our
                  partners.
                </p>
                <div className="why-choose-us-main__button-main">
                  <Link
                    style={{
                      background: "rgb(16, 170, 62)",
                      padding: "10px",
                      color: "#FFF",
                      borderRadius: 4,
                    }}
                    to="/packages"
                  >
                    Buy now
                  </Link>
                </div>
              </div>

              <div className="why-choose-us-main__content-img">
                <img src={about_image} alt="" />
              </div>
            </Fade>
          </div>
        </div>
      </>
    );
  }
}

export default HomeWhyChooseUs;
