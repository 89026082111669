import React from 'react';
import PrivacyPolicyDescription from './privacy-policy-components/privacy-policy-description/privacy-policy-description';
import PrivacyPolicyHeader from './privacy-policy-components/privacy-policy-header/privacy-policy-header';
import './privacy-policy-screen.css';

// components


class PrivacyPolicyScreen extends React.Component{
    
    render(){
        return (<>
               <div className="privacy-policy">
                   <PrivacyPolicyHeader/>
                   <PrivacyPolicyDescription/>
               </div>
        </>);
    }
}

export default PrivacyPolicyScreen;