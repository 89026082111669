import React from "react";
import "./packages-header.css";
import imgHeader from "../../../../../assets/images/backgrounds/BANNERPACKAGES.jpg";

// assets
import package5 from './images/5.png';
import package7 from './images/7.png';
import package6 from './images/6.png';
import package4 from './images/1.png';
import package3 from './images/2.png';
import package2 from './images/3.png';
import package1 from './images/4.png';

class PackageHeader extends React.Component {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
  }
  componentDidMount() {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-tickers.js";
    script.async = true;
    script.innerHTML = {
      symbols: [
        {
          proName: "FOREXCOM:SPXUSD",
          title: "S&P 500",
        },
        {
          proName: "FOREXCOM:NSXUSD",
          title: "Nasdaq 100",
        },
        {
          proName: "FX_IDC:EURUSD",
          title: "EUR/USD",
        },
        {
          proName: "BITSTAMP:BTCUSD",
          title: "BTC/USD",
        },
        {
          proName: "BITSTAMP:ETHUSD",
          title: "ETH/USD",
        },
      ],
      colorTheme: "light",
      isTransparent: false,
      showSymbolLogo: true,
      locale: "es",
    };
    this._ref.current.appendChild(script);
  }
  render() {
    return (
      <>
        <div className="packages">
          <div className="packages__main">
            <img src={imgHeader} alt="" />
            <div
              class="tradingview-widget-container__widget"
              ref={this._ref}
            ></div>
            <div class="tradingview-widget-copyright"></div>
          </div>

          <div className="investment-main">
            <div className="investment-main__title">
              <h1>OUR INVESTMENT RANGES</h1>
              <div className="investment-main__decoration main-color-background"></div>
              <p>
                We strive to produce a balanced profit-taking all sides into
                account.
              </p>
              <br />
            </div>
            <div className="investment-main__packages">
              <div className="investment-main__package">
                <img src={package1} alt="" />
                <div className="investment-main__button">
                  <button>BUY NOW</button>
                </div>
              </div>

              <div className="investment-main__package">
                <img src={package2} alt="" />
                <div className="investment-main__button">
                  <button>BUY NOW</button>
                </div>
              </div>

              <div className="investment-main__package">
                <img src={package3} alt="" />
                <div className="investment-main__button">
                  <button>BUY NOW</button>
                </div>
              </div>

              <div className="investment-main__package">
                <img src={package4} alt="" />
                <div className="investment-main__button">
                  <button>BUY NOW</button>
                </div>
              </div>
            </div>
            <div className="investment-main__packages">
              <div className="investment-main__package">
                <img src={package5} alt="" />
                <div className="investment-main__button">
                  <button>BUY NOW</button>
                </div>
              </div>

              <div className="investment-main__package">
                <img src={package6} alt="" />
                <div className="investment-main__button">
                  <button>BUY NOW</button>
                </div>
              </div>

              <div className="investment-main__package">
                <img src={package7} alt="" />
                <div className="investment-main__button">
                  <button>BUY NOW</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PackageHeader;
