import React from 'react';
import './terms-of-service-header.css';

import trucashIMG from '../../../../../assets/images/simple-images/BANNERABSUPORT.jpg';
// components


class TermsOfServiceHeader extends React.Component{
    
    render(){
        return (<>
               <div className="terms-of-service-header">
                    <img src={trucashIMG} alt=""/>
               </div>
        </>);
    }
}

export default TermsOfServiceHeader;