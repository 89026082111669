import React, { Component } from "react";
import { Link } from "react-router-dom";

class BusinessScreen extends Component {
  render() {
    return (
      <div className="ui mobile reversed equal width grid container">
        <div className="column">
          <h1 style={{ marginTop: 150, fontSize: 20 }}>
            Saint Vincent and The Grenadines Coming
          </h1>
          <h3 style={{ fontSize: 15 }}> soon Exchange licence</h3>
        </div>
        <div className="column">
          <div style={{ textAlign: "center", marginBottom: "450px" }}>
            <h1 style={{ marginTop: "20rem", fontSize: 25 }}>
              Certificate of Incorporation
            </h1>

            <Link
              className="btn btn-outline-success"
              to="pdfs/certificate.pdf"
              target="blank"
            >
              Show Certificate
            </Link>
          </div>
        </div>
        <div className="column">
          <h1 style={{ marginTop: 150, fontSize: 20 }}>
            {" "}
            Curazao Coming soon gamer licence
          </h1>
        </div>
      </div>
    );
  }
}

export default BusinessScreen;
