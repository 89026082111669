import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { NavLink } from "react-router-dom";

class DropdownItem extends React.Component{

   title = this.props.name;
   listItems = this.props.items.map((item, index) => {
     if(item.route !== "#"){
      return (
        <Dropdown.Item as={NavLink} to={item.route} key={index} >
           <span style={{ color: 'black' }} >{item.name}</span> 
        </Dropdown.Item>
      )
     }
      return (
        <Dropdown.Item key={index} text={item.name} />
      )
   }
        
  );

    render(){
        return (<>
       
          <Dropdown pointing={this.props.pointing} text={this.title}>
          
            <Dropdown.Menu>
              {this.listItems}
            </Dropdown.Menu>
       
          </Dropdown>
         
        </>);
    }
}

export default DropdownItem;

