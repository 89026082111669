import React from 'react';
import styled from 'styled-components';


import './home-investment.css';
import Fade from 'react-reveal/Fade';

// assets
import package4 from './images/1.png';
import package3 from './images/2.png';
import package2 from './images/3.png';
import package1 from './images/4.png';

const Title = styled.h1`
    color: rgb(88, 88, 88) !important;
`;
const Paragraph = styled.p`
    color: rgb(88, 88, 88) !important;
`;


class HomeInvestment extends React.Component{
    
    render(){
        return (<>
            <div className="investment-main">
              
                <div className="investment-main__title">
                    <Title>OUR INVESTMENT RANGES</Title>
                    <div className="investment-main__decoration main-color-background"></div>
                    <Paragraph>We strive to produce a balanced profit-taking all sides into account. See all investment ranges</Paragraph>
                    <br/>
                </div>
                <div className="investment-main__packages">
                    <Fade  >
                    <div className="investment-main__package">
                        <img style={{width: "100%"}} src={package1} alt=""/>
                        <div className="investment-main__button">
                        <button>BUY NOW</button>
                        </div>
                        
                    </div>
                    </Fade>
                    <Fade  delay={100}>
                    <div className="investment-main__package">
                        <img style={{width: "100%"}} src={package2} alt=""/>
                        <div className="investment-main__button">
                        <button>BUY NOW</button>
                        </div>
                    </div>
                    </Fade>
                    <Fade  delay={200}>
                    <div className="investment-main__package">
                        <img style={{width: "100%"}} src={package3} alt=""/>
                        <div className="investment-main__button">
                        <button>BUY NOW</button>
                        </div>
                    </div>
                    </Fade>
                    <Fade  delay={300}>
                    <div className="investment-main__package">
                        <img style={{width: "100%"}} src={package4} alt=""/>
                        <div className="investment-main__button">
                        <button>BUY NOW</button>
                        </div>
                    </div>
                    </Fade>
                </div>

               
            </div>
        </>);
    }
}

export default HomeInvestment;