import React from 'react';
import './about-us-offerings.css';

class AboutUsOfferings extends React.Component{
    
    render(){
        return (<>
              <div className="about-us-offerings">

                       
                    <h1>Investment offerings that fit your needs</h1>
                    <p>Taurus Capital is a technology development and private investment group. Its blockchain solutions in the market can help anyone with or without experience, small and medium investors to join the field of cryptocurrencies with the highest security.</p>

                    <h1>Eco-solar Mining</h1>
                    <p>The company also has contracted eco-solar cryptocurrency mining centers in Iceland and soon in Texas, USA. So far, we have a mining capacity of 100 megawhats.</p>
              </div>
        </>);
    }
}

export default AboutUsOfferings;