import React from 'react';
import './trucash-ecosystem.css';


class TrucashEcosystem extends React.Component{
    
    render(){
        return (<>
               <div className="sub-main-trucash-ecosystem">
                   <div className="sub-main-trucash-ecosystem__base-info">
                       <div className="sub-main-trucash-ecosystem__info">
                           <h1>Ecosystem Trucash</h1>
                           <h2>ECOSYSTEM</h2>
                           <p>1- Within the system all the funds that are entered become trucash and when acquiring the package or requesting a withdrawal, an inverse conversion is automatically made according to the type of currency you request.</p>
                            <p>2- The exclusive payment system for merchants, users can buy or sell in the merchants associated with our payment network and in any associated cryptocurrency ATM in the world.</p>
                            <p>3- The hybrid Exchange (Forex-Cryptocurrencies-binary options) allows thousands of users to make exchanges at low cost paying fees with Trucash.</p>
                            <p>4- Other products such as Cryptocasino, Lotteria, sports betting, bingo and The leverage force of Marketing to promote trucash and establish the payment network in stores.</p>
                       </div>
                   </div>
               </div>
        </>);
    }
}

export default TrucashEcosystem;