import React from "react";

import { Link } from "react-router-dom";
import "./trucash-what-is.css";

class TrucashWhatIs extends React.Component {
  render() {
    return (
      <>
        <div className="sub-main-trucash-what-is">
          <div className="sub-main-trucash-what-is__base-info">
            <div className="sub-main-trucash-what-is__info">
              <h1>What is Trucash</h1>
              <h2>TRUCASH</h2>
              <p>
                TCH is an open source token based on the Libra blockchain
                (Facebook). The goal of Trucash cryptocurrency is to become a
                mainstream digital currency that can be used by anyone. By
                charging much cheaper fees than other money sending services,
                utilizing blockchain technology, and holding the value of the
                currency stable, Trucash wants to provide better, cheaper, and
                more open access to financial services for all. The primary
                purpose of Trucash is to be used in cross-border payments and
                money transfers.
              </p>
              <p>
                Trucash is another source of income for our partners because the
                amount of this cryptocurrency is limited and the neeed to obtain
                it is constantly growing. That means that the more transactions,
                the more the supply and demand increase. You could even increase
                your profits by more than 4,000%.
              </p>
              <p>Trucash Token (TCH)</p>
              <p>
                TCH is the token used for Trucash. Token purchasers, crypto
                traders, crypto developers and various trading platforms can
                also use TCH as “gas” to fuel transactions in the Trucash
                ecosystem.
              </p>
              <Link
                style={{ padding: "16px" }}
                className=" sub-main-trucash-what-is__info__btn "
                to="pdfs/TruCash-WhiteBook(eng).pdf"
                target="blank"
              >
                Download White Paper
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TrucashWhatIs;
