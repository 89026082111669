import React from 'react';
import './packages-investment.css';

import packages from '../../../../../assets/images/simple-images/img46.jpg';
import bitCoins from '../../../../../assets/images/simple-images/bitcoinMesa-de-trabajo-96@4x-2048x929.png';

class PackageInvestment extends React.Component{
  
    render(){
        return (<>
              <div className="packages-investment">
                    <img className="investment" src={packages} alt=""/>
                    <img src={bitCoins} alt=""/>
              </div>
        </>);
    }
}

export default PackageInvestment;