import React from 'react';
import './products-our.css';


// assets


class ProductsOur extends React.Component{
  
    render(){
        return (<>
              <div className="products-our">
                  <div className="products-our__title">
                      <h1>OUR PRODUCTS</h1>
                  </div>
                  <div className="products-our__info">
                      <div className="products-our__section">
                      <h3>MINNING MACHINE</h3>
                      <p>Our eco-friendly crypto mining techniques is aimed at making Cryptocurrency More Environmentally Sustainable  and reduce energy consumption.</p>
                      </div>

                      <div className="products-our__section">
                      <h3>TRADING BOT</h3>
                      <p>Our Trader Bot offers a number of advanced features. It is also a platform that both novice and advanced traders have found to be easy to use.</p>
                      </div>

                      <div className="products-our__section">
                      <h3>TRADING SIGNALS</h3>
                      <p>We mitigate risk by having a small percentage of capital in each deal along with recommended safety trades. Our trading Signals are meant for quick entry and exit deals for small profits that accumulate.</p>
                      </div>
                  </div>
                  
              </div>
        </>);
    }
}

export default ProductsOur;