import React from "react";
import "./footer.css";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

import logo from "../../assets/images/simple-images/Logo-300x130.png";
import {
  faFacebook,
  faTwitter,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <MDBFooter
        color="elegant-color-dark"
        id="no-margin"
        className="font-small pt-4 mt-4 "
      >
        <MDBContainer fluid className="text-center text-md-left">
          <MDBRow>
            <MDBCol md="6">
              <img className="logo" src={logo} alt="" />
              <p className="descripction">
                Taurus Capital is a program of online investment. It was
                constituted by a group of entrepreneurs from 7 different
                industries which together add more than 50 years of experience.
              </p>
              <br />
            </MDBCol>
            <MDBCol md="3">
              <h5 className="title">QUICK LINK :</h5>
              <Link to="/">Home</Link> /<Link to="/about">About</Link> /
              <Link to="/packages">Package</Link> /
              <Link to="/products">Products</Link> /
              <Link to="/trucash">Trucash</Link> /{" "}
              <Link to="/privacy-policy">Privacy policy</Link>
            </MDBCol>

            <MDBCol md="3">
              <h5 className="title">CONTACT :</h5>

              <a href="#!">+1 305-777-7852</a>
              <br />
              <a href="#!">support@tauruscapital.biz</a>

              <br />
              <a className="netw" href="#!">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a className="netw" href="#!">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a className="netw" href="#!">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a className="netw" href="#!">
                <FontAwesomeIcon icon={faInstagram} />
              </a>

              <br />
              <br />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <div className="footer-copyright text-center py-3">
          <MDBContainer fluid>
            &copy; Taurus Capital Copyright © {new Date().getFullYear()} All
            rights reserved.:{" "}
            <a href="https://tauruscapital.biz/w/"> tauruscapital.biz </a>
          </MDBContainer>
        </div>
      </MDBFooter>
    );
  }
}

export default Footer;
