import React from 'react';
import './trucash-technical.css';

import farm from '../../../../../assets/images/simple-images/farm_radeon5.png';

class TrucashTechnical extends React.Component{
    
    render(){
        return (<>
               <div className="sub-main-trucash-technical">
                   <div className="sub-main-trucash-technical__sections">
                       <div className="sub-main-trucash-technical__section">
                           <h1>TECHNICAL DATA</h1>
                           <p>Trucash uses the latest technologies to process fast transfers, with a combination of CPU / GPU and POW / POS (proof of stake) logic efficiently at the right time</p>
                           <p>TCH is an open source token based on the Libra blockchain (Facebook).</p>
                           <img src={farm} alt=""/>
                       </div>
                       <div className="sub-main-trucash-technical__section">
                           <h1>NAME: Trucash (TCH)</h1>
                           <h2>Total supply: 42,000,000</h2>
                           <h3>Private</h3>
                           <p>Private Sale – 7% $ 0.10</p>
                           <p>Private Sale – 5% $ 0.35</p>
                           <p>Private Sale – 3% $ 0.75</p>
                           <p>Private Sale – 5% $ 1</p>
                           <h3>Public</h3>
                           <p>Public Sale – 5% $ 1.5</p>
                           <p>Public Sale – 5% $ 3</p>
                       </div>

                   </div>
               </div>
        </>);
    }
}

export default TrucashTechnical;