import React from 'react';
import MembershipProgramDescription from './membership-program-components/membership-program-description/membership-program-description';
import MembershipProgramHeader from './membership-program-components/membership-program-header/membership-program-header';

import './membership-program-screen.css';

// components


class MembershipProgramScreen extends React.Component{
    
    render(){
        return (<>
               <div className="sub-main-membership">
                    <MembershipProgramHeader/>
                    <MembershipProgramDescription/>
               </div>
        </>);
    }
}

export default MembershipProgramScreen;