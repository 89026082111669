import React from "react";
import "./home-ecosystem.css";
import Fade from "react-reveal/Fade";

// assets
import ecosystemImage from "./ecosistema.png";

class HomeEcosystem extends React.Component {
  render() {
    return (
      <>
        <div className="ecosystem-main">
          <div className="ecosystem-main__info">
            <Fade>
              <h1>Ecosystem</h1>
              <p>
                We have built a sustainable ecosystem that is rapidly expanding
                worldwide. The main basis is usability on and off our platforms.
                This enables the inevitable growth of Taurus Capital in digital
                payments. <br />
                <br />
                The objective of our ecosystem has been to build a
                cryptocurrency with real value and one in which the triple
                ecosystem is based on a volume of operations.
              </p>
            </Fade>
          </div>
          <div className="ecosystem-main__img">
            <img style={{marginTop: '3rem'}} src={ecosystemImage} alt="" />
          </div>
        </div>
      </>
    );
  }
}

export default HomeEcosystem;
