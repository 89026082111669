import React from 'react';
import './contact-screen.css';

// components
import ContactHeader from './contact-components/contact-header/contact-header';
import ContactInfo from './contact-components/contact-info/contact-info';


class ContactScreen extends React.Component{
    
    render(){
        return (<>
               <div className="sub-main-membership">
                    <ContactHeader/>
                    <ContactInfo/>
               </div>
        </>);
    }
}

export default ContactScreen;