import React from 'react';
import './about-us-second.css';
import imgAboutUs from '../../../../../assets/images/simple-images/about-us-600-x-800.png';

class AboutUsSecond extends React.Component{
    
    render(){
        return (<>
              <div className="about-us-second">

                       
                        <div className="about-us-second__img">
                            <img src={imgAboutUs} alt=""/>
                        </div>

                        <div className="about-us-second__info">
                            <h1>Digital Assets</h1>
                            <div className="about-us-second__deco"></div>

                            <p>Taurus Capital is a company that specializes in digital assets and distributed accounting technology. We have been behind the development of important blockchain projects and several other mouth-watering investment programs. Our financing round incluses private investors, strategic investors, as well as new strategic institutions.</p>
                            <p>We are building one of the world’s most promising platforms – from distribution to custody and the transfer of cryptocurrencies, using distributed ledger and smart contract technology. The launch of our Hybrid Exchange and payment system, as well as a complete blockchain communication infrastructure, will allow companies in the field of cryptocurrencies to expand their services and activate digital assets at a lower rate. Taurus works by combining traditional and digital assets to aid innovation.</p>
                        </div>

              </div>
        </>);
    }
}

export default AboutUsSecond;