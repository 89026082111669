import React from 'react';
import ReferralProgramFinalSection from './referral-program-components/referral-program-final-section/referral-program-final-section';
import ReferralProgramHeader from './referral-program-components/referral-program-header/referral-program-header';
import ReferralProgramPartners from './referral-program-components/referral-program-partners/referral-program-partners';

import './referral-program-screen.css';

// components


class ReferralScreen extends React.Component{
    
    render(){
        return (<>
               <div className="sub-main-referral">
                   <ReferralProgramHeader/>
                   <ReferralProgramPartners/>
                   <ReferralProgramFinalSection/>
               </div>
        </>);
    }
}

export default ReferralScreen;