import React from 'react';
import './leadership-program-description.css';

// assets
import img from '../../../../../assets/images/simple-images/lista-de-premios-Ingles-Completa-Traducida-1536x768.png';

class LeadershipProgramDescription extends React.Component{
  
    render(){
        return (<>
              <div className="leadership-description">
                  <h1>LEADERSHIP PROGRAM</h1>
                  <div className="divider"></div>
                  <p>Taurus Capital provides its partners with a career ladder of 13 leadership ranks that are accompanied with incredible rewards. To achieve any of the ranks in the leadership board, it is required to fulfill some of the conditions below:</p>
                    <p>The higher your leadership rank, the more referral lines are available to you. Moreso, your referral bonus will grow with each new status achieved.</p>
              </div>
              <img className="img-leadership" src={img} alt=""/>
        </>);
    }
}

export default LeadershipProgramDescription;