import React from 'react';
import './referral-program-header.css';
import  imgHeader from '../../../../../assets/images/simple-images/BANNERpartner.jpg';

// assets


class ReferralProgramHeader extends React.Component{
  
    render(){
        return (<>
              <div className="referral-program-header">
                    <img src={imgHeader} alt=""/>
              </div>
        </>);
    }
}

export default ReferralProgramHeader;