import React from 'react';
import './trucash-header.css';

import trucashIMG from '../../../../../assets/images/simple-images/BANNERtrucash.jpg';
// components


class TrucashHeader extends React.Component{
    
    render(){
        return (<>
               <div className="sub-main-trucash-header">
                    <img src={trucashIMG} alt=""/>
               </div>
        </>);
    }
}

export default TrucashHeader;