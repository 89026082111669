import React from 'react';
import TermsOfServiceDescription from './terms-of-service-components/terms-of-service-description/terms-of-service-description';
import TermsOfServiceHeader from './terms-of-service-components/terms-of-service-header/terms-of-service-header';
import './terms-of-service-screen.css';

// components


class TermOfServiceScreen extends React.Component{
    
    render(){
        return (<>
               <div className="terms-of-service">
                    <TermsOfServiceHeader/>
                    <TermsOfServiceDescription/>
               </div>
        </>);
    }
}

export default TermOfServiceScreen;