import React from 'react';
import './privacy-policy-header.css';

import trucashIMG from '../../../../../assets/images/simple-images/BANNERABSUPORT.jpg';
// components


class PrivacyPolicyHeader extends React.Component{
    
    render(){
        return (<>
               <div className="privacy-policy-header">
                    <img src={trucashIMG} alt=""/>
               </div>
        </>);
    }
}

export default PrivacyPolicyHeader;