import React from 'react';
import LeadershipProgramDescription from './leadership-program-components/leadership-program-description/leadership-program-description';
import LeadershipProgramHeader from './leadership-program-components/leadership-program-header/leadership-program-header';
import './leadership-program-screen.css';

// components


class LeadershipProgramScreen extends React.Component{
    
    render(){
        return (<>
               <div className="sub-main-leadership">
                    <LeadershipProgramHeader/>
                    <LeadershipProgramDescription/>
               </div>
        </>);
    }
}

export default LeadershipProgramScreen;