import React from "react";
import "./privacy-policy-description.css";

// components

class PrivacyPolicyDescription extends React.Component {
  render() {
    return (
      <>
        <div className="privacy-policy-description">
          <h1>Privacy policy</h1>
          <div className="divider"></div>
          <p>
            This privacy policy (this “Privacy Policy”) explains how personal
            information is collected, used, stored, and disclosed by Taurus
            Capital Trade Corporation, (“tauruscapital.biz,” “we,” “us,” and
            “our”). This Privacy Policy applies to users (individually referred
            to as “you”) of our website, applications, and other online services
            to which this Privacy Policy is posted (collectively, our
            “Services”). This Privacy Policy is part of our Terms of Use. By
            accessing or using our Services, you agree to this Privacy Policy
            and our Terms of Use. The provisions contained in this Privacy
            Policy supersede all previous notices and statements regarding our
            privacy practices with respect to our Services. If you do not agree
            to every provision of this Privacy Policy and our Terms of Use, you
            may not access or use our Services.
          </p>
          <h2>Application of this Privacy Policy</h2>
          <div className="divider"></div>
          <p>
            This Privacy Policy applies to your use of (regardless of means of
            access) our Services. You may access or use our Services through a
            pc, mobile phone, tablet, or other consumer electronic device (each,
            a “Device”).
          </p>
          <h2>Information We Collect</h2>
          <h4>Information You Provide Us </h4>
          <div className="divider"></div>
          <p>
            In general, you can visit https://tauruscapital.biz without telling
            us who you are or revealing any information about yourself. When you
            submit an inquiry via our Services or sign up, we may collect
            personal information from you, which may include your name, email
            address, mobile phone number, and other information that identifies
            you (collectively, “Personal Information”). By providing your
            Personal Information to us, you expressly agree to our collection,
            use, storage, and disclosure of such information as described in
            this Privacy Policy. We may also ask you to create login information
            for your Taurus Capital account, such as a username and password.
            When you provide your mobile phone number, we may ask for your
            consent to receive text messages relating to our Services at that
            number.
          </p>
          <h2>Information About Your Transactions</h2>
          <div className="divider"></div>
          <p>
            We collect Personal Information about your transactions with us and
            other users
          </p>
          <h2>Information Automatically Collected </h2>
          <div className="divider"></div>
          <p>
            We (or our service providers acting on our behalf) may collect
            information about your use of our Services. This information may
            include Personal Information as well as statistical information that
            does not identify you (“Analytics”). Some Analytics may be
            correlated with Personal Information. When Analytics are, directly
            or indirectly, associated or combined with Personal Information,
            such Analytics will be considered Personal Information for purposes
            of this Privacy Policy. Information that we automatically collect in
            connection with your access or use of our Services may include:
          </p>
          <h5>Device Information:</h5>
          <p>
            We may collect Device-specific information (such as hardware model,
            operating system version, unique Device identifiers, and mobile
            network Information, including your mobile phone number). We may
            associate your Device identifiers or mobile phone number with your
            Taurus Capital account.
          </p>
          <h5>Log Information: </h5>
          <p>
            We may record or log information from your Devices, their software,
            and your activity accessing or using our Services. This information
            may include:
          </p>
          <p>The Device’s Internet Protocol (“IP”) address </p>
          <p>
            Device event information, such as crashes, system activity, and
            hardware settings
          </p>
          <p>Location preferences</p>
          <p>Date and time stamps of transactions</p>
          <p>System configuration information</p>
          <p>Other interactions with our Services</p>
          <p>Information Collected Through Cookies and Similar Technologies </p>
          <p>
            We use cookies to personalize our Services for you and to collect
            aggregate information about usage of our Services. A cookie is a
            text file or other local storage identifier provided by your browser
            or associated applications. We use cookies for record-keeping
            purposes and to enhance the quality of your use of our Services.{" "}
          </p>
          <p>
            By accessing or using our Services, you consent to the placement of
            cookies on your Devices as described in this Privacy Policy. If you
            prefer not to receive cookies through our Services, you may control
            how your browser responds to cookies by adjusting the privacy and
            security settings of your web browser. Unless you set your browser
            settings to refuse all cookies, our system may issue cookies when
            you access or use our Services. If you set your browser settings to
            refuse all cookies, the performance of certain features of our
            Services may be limited or not work at all.
          </p>
          <h2>How We Use Information </h2>
          <div className="divider"></div>
          <p>
            We may use Analytics as described elsewhere in this Privacy Policy
            and for research and commercial purposes, such as to improve our
            Services.{" "}
          </p>
          <p>
            We may use Personal Information for the purposes described elsewhere
            in this Privacy Policy and internally for our general commercial
            purposes, including, among other things, to offer our products and
            services and products and services of third parties that we think
            you might find of interest, but only Taurus Capital Trade
            Corporation and our thirdparty service providers involved in
            distributing the offers or providing the products or services will
            have access to your Personal Information. Our third-party service
            providers will only be permitted to use Personal Information for
            that intended purpose.
          </p>
          <p>
            We may use your email address to respond to your inquiries and to
            provide you information about our Services. You may elect not to
            receive promotional emails from us either by “unsubscribing” to an
            email you receive from us or by contacting us as indicated below. If
            you unsubscribe from receiving emails from us, we may still send you
            non-promotional emails, such as emails about your Taurus Capital
            account or our ongoing business relations, unless you withdraw your
            consent to receive electronic communications as provided in our
            Terms of Use.
          </p>
          <h2>How We Share Information</h2>
          <div className="divider"></div>
          <p>
            {" "}
            We{" "}
            <span style={{  fontWeight: "bold" }}>
              do not share
            </span>{" "}
            your Personal Information with: (1) financial companies for joint
            marketing purposes; (2) affiliated companies for their everyday
            business purposes; or (3) any third parties so they can market to
            you.
          </p>
          <p>
            We may share your Personal Information with unaffiliated third
            parties: (1) if you request or authorize it; (2) if the information
            is provided to help complete a transaction for you; (3) if the
            information is provided to: (a) comply with applicable laws, rules,
            regulations, governmental and quasi-governmental requests, court
            orders, or subpoenas; (b) enforce our Terms of Use or other
            agreements; or (c) protect our rights, property, or safety or the
            rights, property, or safety of our users or others (e.g., to a
            consumer reporting agency for fraud protection, etc.); (4) if the
            disclosure is done as part of a purchase, transfer, or sale of
            services or assets (e.g., in the event that substantially all of our
            assets are acquired by another party, your Personal Information may
            be one of the transferred assets); (5) if the information is
            provided to our third-party service providers to perform functions
            on our behalf (e.g., analyzing data, providing marketing assistance,
            providing customer service, processing orders, etc.); (6) for our
            everyday business purposes; or (7) as permitted by applicable law or
            otherwise described in this Privacy Policy. When you are no longer
            our customer, we continue to share your information as described in
            this Privacy Policy.
          </p>
          <p>
            We may disclose Analytics with third parties as described elsewhere
            in this Privacy Policy and for our commercial purposes.
          </p>
          <h2>Accessing Your Information</h2>
          <div className="divider"></div>
          <p>
            You must notify us of any change in your Personal Information by
            updating your Taurus Capital account profile through our Services.
            Any changes will affect only future uses of your Personal
            Information.{" "}
          </p>
          <p>
            Subject to applicable law, which might, from time to time, oblige us
            to store your Personal Information for a certain period of time, we
            will respect your wishes to correct inaccurate information.
            Otherwise, we will hold your Personal Information for as long as we
            believe it will help us achieve our objectives as detailed in this
            Privacy Policy.
          </p>
          <p>
            You can ask us whether we are storing your Personal Information and
            you can ask to receive a copy of that Personal Information. Before
            sending you any Personal Information, we will ask you to provide
            proof of your identity. If you are not able to provide proof of your
            identity, we reserve the right to refuse to send you any Personal
            Information. We will respond as quickly as we can to your requests
            for details of Personal Information we hold about you.
          </p>
          <h2>Information You Share Socially</h2>
          <div className="divider"></div>
          <p>
            Our Services may allow you to connect and share your actions,
            comments, content, and information publicly or with friends. We are
            not responsible for maintaining the confidentiality of any
            information you share publicly or with friends.
          </p>
          <p>
            Our Services may also allow you to connect with us on, share on, and
            use third-party websites, applications, and services. Please be
            mindful of your personal privacy needs and the privacy needs of
            others, as you choose whom to connect with and what to share and
            make public. We cannot control the privacy or security of
            information you choose to make public or share with others. We also
            do not control the privacy practices of third parties. Please
            contact those sites and services directly if you want to learn about
            their privacy practices
          </p>
          <h2>Security</h2>
          <div className="divider"></div>
          <p>
            We have, and require our third-party service providers that receive
            Personal Information from us to have, a comprehensive written
            information security program that contains administrative,
            technical, and physical safeguards for our respective physical
            facilities and in our respective computer systems, databases, and
            communications networks that are reasonably designed to protect
            information contained within such systems from loss, misuse, or
            alteration. When account information is transmitted via our
            Services, it will be protected by encryption technology, such as
            Secure Sockets Layer (SSL).
          </p>
          <p>
            No method of electronic transmission or storage is 100% secure.
            Therefore, we cannot guarantee absolute security of your Personal
            Information. You also play a role in protecting your Personal
            Information. Please safeguard your username and password for your
            Taurus Capital account and do not share them with others. If we
            receive instructions using your Taurus Capital account login
            information, we will consider that you have authorized the
            instructions. You agree to notify us immediately of any unauthorized
            use of your Taurus Capital account or any other breach of security.
            We reserve the right, in our sole discretion, to refuse to provide
            our Services, terminate Taurus Capital accounts, and to remove or
            edit content.
          </p>
          <h2>Protecting Children’s Privacy </h2>
          <div className="divider"></div>
          <p>
            Our Services are not directed, or intended to be attractive, to
            children under the age of 18. We do not knowingly collect Personal
            Information from children under the age of 18. If you are under the
            age of 18, do not use our Services or submit any information to us.
          </p>
          <h2>Changes to our Privacy Policy </h2>
          <div className="divider"></div>
          <p>
            Subject to applicable law, we may revise this Privacy Policy at any
            time and in our sole discretion. When we revise this Privacy Policy,
            we will post the revised version via our Services and will update
            the date at the top of this Privacy Policy. The revised Privacy
            Policy will be effective upon posting via our Services, unless
            otherwise set forth therein or as otherwise required by applicable
            law. You are free to decide whether or not to accept a revised
            version of this Privacy Policy, but accepting this Privacy Policy,
            as revised, is required for you to continue accessing or using our
            Services. If you do not agree to the terms of this Privacy Policy or
            any revised version of this Privacy Policy, your sole recourse is to
            terminate your access and use of our Services. Except as otherwise
            expressly stated by us, your access and use of our Services is
            subject to the version of this Privacy Policy in effect at the time
            of access or use.
          </p>
          <p>
            If you have any questions, comments, or concerns regarding these
            Privacy Policy, please contact us at <span style={{fontWeight: 'bold'}}> support@tauruscapital.biz</span>.
          </p>
        </div>
      </>
    );
  }
}

export default PrivacyPolicyDescription;
