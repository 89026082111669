import React from 'react';
import './info-header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume, faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Info = [
    { icon: faPhoneVolume, value: '+ 1 305-777-7852' },
    { icon: faEnvelope, value: 'support@tauruscapital.biz' }
];

const listItems = Info.map((item, index) =>
    <div key={index} className="info-header__item"> 
        <span className="info-header__text"><FontAwesomeIcon icon={item.icon} /> {item.value}</span> 
    </div>
);
class InfoHeader extends React.Component{
    
    render(){
        return (<>
               <div className="info-header">
                     <div className="info-header__content">
                         {listItems}
                     </div>
                 </div>
        </>);
    }
}

export default InfoHeader;