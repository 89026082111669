import React from "react";
import "./membership-program-description.css";

import gold from "./images/gold.png";
import black from "./images/black.png";
// components

class MembershipProgramDescription extends React.Component {
  render() {
    return (
      <>
        <div className="sub-main-membership-description">
          <div className="sub-main-membership-description__title">
            <h1>Membership Program</h1>

          
            <p>
              To be part of our company, you must choose one of the 2
              memberships available below:
            </p>
          </div>

          <div className="sub-main-membership-description__content">
            <div className="sub-main-membership-description__section">
              <div style={{ textAlign: "center", width: "100%" }}>
                <img style={{ width: 250,  marginTop: '1rem' }} src={gold} alt="Gold Membership" />
                <h1>Gold Membership</h1>
              </div>

              <br />
              <br />
              <p>
                Gold is a premium membership that includes a license to market
                all our products and services. The subscription costs only
                $29.99, which is debited after 60 days of subscribing. However,
                it becomes a monthly fee when you qualify as a Taurus
                Coordinator.
              </p>
              <p>Features of this Package:</p>
              <p>
                You acquire a package of services plus an exclusive trade
                license of US $29.99 / month.
              </p>
              <p><i class="fas fa-caret-right"></i> You are eligible to participate in the compensation plan</p>
              <p><i class="fas fa-caret-right"></i> You get the leadership awards</p>
              <p><i class="fas fa-caret-right"></i> You qualify for quarterly travels and conventions.</p>
              <p><i class="fas fa-caret-right"></i> You receive promotional items</p>
              <p><i class="fas fa-caret-right"></i> step by step guide to generating $10,000 in commissions</p>
              <p><i class="fas fa-caret-right"></i> Unlimited access to Taurus Education university</p>
            </div>
            <div className="sub-main-membership-description__section">
              <div style={{ textAlign: "center", width: "100%" }}>
                <img
                  style={{ width: "250px", marginTop: '1rem' }}
                  src={black}
                  alt="Membership Program"
                />
                <h1>Black Partner Membership</h1>
              </div>

              <br />
              <br />
              <p>
                Black Partner is a FREE membership that allows you to access the
                benefits of some of our products and services.
              </p>
              <p>Features of this Package:</p>
              <p>
              <i class="fas fa-caret-right"></i> You acquire a package of services and obtain attractive weekly
                returns.
              </p>
              <p><i class="fas fa-caret-right"></i> Limited access to Taurus Education university</p>
              <p><i class="fas fa-caret-right"></i> Without participation in the compensation plan</p>
              <p><i class="fas fa-caret-right"></i> You DO NOT pay license value fee</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MembershipProgramDescription;
