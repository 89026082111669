import React from 'react';
import './products-why-use.css';

// assets
import check from '../../../../../assets/images/simple-images/1200px-Check_green_icon.svg.png';

class ProductsWhyUse extends React.Component{
  
    render(){
        return (<>
              <div className="products-why-use">
                  <h2>Why Use our Products</h2>
                  <h3> <img src={check} alt=""/> Tested and Proven Strategies</h3>
                  <h3> <img src={check} alt=""/> Money Management Capabilities</h3>

                  <h3> <img src={check} alt=""/> Works with any Currency Pair and Timeframe</h3>

                  <h3> <img src={check} alt=""/> Lifetime Warranty</h3>

                  <h3> <img src={check} alt=""/> Quick Installation</h3>

                  <h3> <img src={check} alt=""/> Customer Support</h3>

              </div>
        </>);
    }
}

export default ProductsWhyUse;