import React from 'react';
import './about-us-header.css';
import  imgHeader from '../../../../../assets/images/backgrounds/BANNERABOU-3.jpg';

class AboutUsHeader extends React.Component{
    
    render(){
        return (<>
              <div className="about-us-header">

                        <div className="about-us-header__main">
                            <img src={imgHeader} alt=""/>
                        </div>

              </div>
        </>);
    }
}

export default AboutUsHeader;