import React, { useState } from "react";
import "./navbar-header.css";
import logo from "../../../assets/images/simple-images/log.png";
import DropdownItem from "./dropdown-item-header";
import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";

const menuItems = [
  { route: "/", name: "Home" },
  { route: "/about", name: "About Us" },
  {
    route: "#",
    name: (
      <DropdownItem
        name="Investments/our pricinc"
        pointing={true}
        items={[
          { route: "/packages", name: "Packages" },
          { route: "/products", name: "Products" },
          { route: "/trucash", name: "Trucash" },
        ]}
      />
    ),
  },
  {
    route: "#",
    name: (
      <DropdownItem
        name="Partners"
        pointing={true}
        items={[
          { route: "/referral-program", name: "Referral Program" },
          { route: "/leadership-program", name: "Leadership Program" },
          { route: "/membership-program", name: "Membership Program" },
        ]}
      />
    ),
  },
  {
    route: "#",
    name: (
      <DropdownItem
        name="Support"
        pointing={true}
        items={[
          { route: "/contact", name: "Contact" },
          {
            route: "#",
            name: (
              <DropdownItem
                name="Legal"
                items={[
                  { route: "/terms-of-service", name: "Terms of Service" },
                  { route: "/privacy-policy", name: "Privacy policy" },
                  { route: "/business", name: "Business" },
                ]}
              />
            ),
          },
        ]}
      />
    ),
  },
];

const listItems = menuItems.map((item, index) => {
  if (item.route !== "#") {
    return (
      <li className="navbar-header__list-item" key={index}>
        <NavLink
          className="navbar-header__list-item-text link-hover"
          activeClassName="active-link"
          to={item.route}
        >
          {item.name}
        </NavLink>
      </li>
    );
  } else {
    return (
      <li className="navbar-header__list-item" key={index}>
        <button className="navbar-header__list-item-text">{item.name}</button>
      </li>
    );
  }
});

const NavbarWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 15vh;
  z-index: 99;
  left: ${(props) => (props.open ? "0" : "-100%")};
  width: 100%;
  height: 90vh;
  transition: right 0.3s linear;

  @media only screen and (min-width: 1080px) {
    flex-direction: row;
    position: initial;
    height: auto;
    justify-content: right;
  }
`;

function Navbar({ open }) {
  return (
    <NavbarWrapper open={open}>
      <ul className="navbar-header__list">
        {listItems}
        <li className="navbar-header__list-item">
          <a
            className="navbar-header__list-item-text link-hover"
            href="https://app.tauruscapital.biz/login"
          >
            Login
          </a>
        </li>
        <li className="navbar-header__list-item">
          <a
            className="navbar-header__list-item-text link-hover"
            href="https://app.tauruscapital.biz/register"
          >
            Register
          </a>
        </li>
      </ul>
    </NavbarWrapper>
  );
}

const MenuButtonWrapper = styled.button`
  border: none;
  box-shadow: 0px 0px 1px rgb(50, 50, 50);
  margin-top: 1.3rem;
  position: absolute;
  right: 20px;
  top: 50px;
  background: none;

  @media only screen and (min-width: 1080px) {
    display: none;
  }
`;

function MenuButton({ open, handleClick }) {
  return !open ? (
    <MenuButtonWrapper onClick={handleClick}>
      <svg viewBox="0 0 100 80" width="30" height="30" fill="white">
        <rect width="90" height="10" />
        <rect y="30" width="80" height="10" />
        <rect y="60" width="70" height="10" />
      </svg>
    </MenuButtonWrapper>
  ) : (
    <MenuButtonWrapper onClick={handleClick}>
      <svg
        className="svg-icon"
        width="30"
        height="30"
        viewBox="0 0 20 20"
        fill="white"
      >
        <path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z" />
      </svg>
    </MenuButtonWrapper>
  );
}

function NavbarHeader() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="navbar-header">
        <div className="navbar-header__content">
          <Link to="/home" className="navbar-header__logo">
            <img id="s" src={logo} alt="Logo" />
          </Link>
          <div className="navbar-header__menu">
            <Navbar open={open} />
            <MenuButton open={open} handleClick={handleClick} />
          </div>
        </div>
      </div>
    </>
  );
}

export default NavbarHeader;
