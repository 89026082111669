import React from "react";
import "./about-us-first.css";
import imgAboutUs from "../../../../../assets/images/simple-images/about-us.png";

class AboutUsFirst extends React.Component {
  render() {
    return (
      <>
        <div className="about-us-first">
          <div className="about-us-first__info">
            <h1>ABOUT US</h1>
            <div className="about-us-first__deco"></div>

            <p>
              Taurus Capital is an online investment program with headquarters
              in United Kingdom The company was constituted by a group of
              entrepreneurs from 7 different industries with more than 50 years
              of experience combined together. Since 2001, they have worked in
              different financial markets and are intimately linked with the
              development of important cryptocurrencies, and other
              blockchain-realted projects on various platforms exchanges. Taurus
              Capital was established in 2018 to provide enlightened financial
              solutions to both individuals, organizations and government
              agencies.
            </p>
            <p>
              In recent years, we have dedicated ourselves in making big
              investments in the development of trading bots and Eco-Solar
              Crypto mining. At the beginning of 2018, we decided to assemble a
              team of experts in blockchain technology, Cryptocurrencies
              (inclusing crypto-mining), stock trading and Multilevel Marketing.
              We want Taurus Capital to be the biggest and most comprehensive
              online investment program project while attracting small and
              medium scale investors.
            </p>
            <p>
              Our team is comprised of specialist consultants who have a deep
              understanding of the commercial intricacies and growth constraints
              faced by businesses in the current financial market. As experts in
              the financial sector and cryptocurrency, we recognise that every
              organization faces unique challenges and operational constraints.
              As a result, we always strive to merge financial management
              best-practices with bespoke solutions that are tailored around
              your vision and current infrastructure.
            </p>
          </div>
          <div className="about-us-first__img">
            <img src={imgAboutUs} alt="" />
          </div>
        </div>
      </>
    );
  }
}
export default AboutUsFirst;
