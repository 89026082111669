import React from 'react';
import './products-header.css';
import  imgHeader from '../../../../../assets/images/simple-images/BANNERProducts.jpg';

// assets


class ProductsHeader extends React.Component{
  
    render(){
        return (<>
              <div className="products">
                    <img src={imgHeader} alt=""/>
              </div>
        </>);
    }
}

export default ProductsHeader;