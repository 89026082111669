import React from "react";
import { Link } from "react-router-dom";
import "./home-about-us.css";
import Fade from "react-reveal/Fade";
import Pulse from "react-reveal/Pulse";
import Particles from "react-particles-js";

// assets
import about_image from "../../../../../assets/images/simple-images/about-1024x986.png";

class HomeAboutUs extends React.Component {
  render() {
    return (
      <>
        <div className="about-us-main">
          <div className="about-us-main__content">
            <Fade bottom duration={700}>
              <div className="about-us-main__content-img">
                <img src={about_image} alt="" />
              </div>
              <div className="about-us-main__content-info">
                <h1>About us</h1>
                <div className="about-us-main__deco-bar main-color-background"></div>
                <p>
                  Taurus Capital is an online investment program with
                  headquarters in United Kingdom The company was constituted by
                  a group of entrepreneurs from 7 different industries with more
                  than 50 years of experience combined together. Since 2001,
                  they have worked in different financial markets and are
                  intimately linked with the development of important
                  cryptocurrencies, and other blockchain-realted projects on
                  various platforms exchanges. Taurus Capital was established in
                  2018 to provide enlightened financial solutions to both
                  individuals, organizations and government agencies
                </p>
                <div className="about-us-main__button-main">
                  <Pulse delay={1000}>
                    <Link to="/about" className="btn main-color-background">
                      Read More
                    </Link>
                  </Pulse>
                </div>
              </div>
            </Fade>
          </div>

          <div className="about-particles">
            <Particles
              params={{
                particles: {
                  number: {
                    value: 15,
                  },
                  color: "#e3af50",
                  line_linked: {
                    color: "#e3af50",
                  },
                  move: {
                    speed: 1,
                  },
                  size: {
                    value: 10,
                  },
                },
                interactivity: {
                  events: {
                    onhover: {
                      enable: true,
                      mode: "grab",
                    },
                    onclick: {
                      enable: true,
                      mode: "push",
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default HomeAboutUs;
