import React from "react";
import styled from "styled-components";

import casino from "./images/casino.png";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-image: url("/assets/bg-casino.jpg");
  background-position: center;
  background-size: cover;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
const View = styled.div`
  width: 40%;
  height: auto;
  display: flex;
  justify-content: center;
  margin: 2rem;
  @media (max-width: 700px) {
    width: 90% !important;
  }
`;

const ViewLink = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkCasino = styled.a`
  padding: 25px 50px 25px 50px;
  font-size: 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: rgb(16, 170, 62);

  @media (max-width: 700px) {
    margin-top: 1rem;
  }
`;

const Image = styled.img`
  vertical-align: middle;
  border-style: none;
  height 35rem;
  border-radius: 50%;

    
  @media (max-width: 700px) {
    height 20rem;
  }
`;

export const CasinoScreen = () => {
  return (
    <>
      <Wrapper>
        <View>
          <ViewLink>
            <LinkCasino href="https://cryptocassino.com/" target="blank">
              Visit casino
            </LinkCasino>
          </ViewLink>
        </View>
        <View>
          <ViewLink>
            <Image src={casino} alt="Casino" />
          </ViewLink>
        </View>
      </Wrapper>
    </>
  );
};
