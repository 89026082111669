import React from "react";
import styled from "styled-components";
import Pulse from "react-reveal/Pulse";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

import "./home-trucash-token.css";

const LinkCasino = styled.a`
  padding: 25px 50px 25px 50px;
  font-size: 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: rgb(250, 179, 48) !important;

  @media (max-width: 700px) {
    margin-top: 1rem;
  }
`;

class HomeTrucashToken extends React.Component {
  render() {
    return (
      <>
        <div className="trucash-token-main">
          <div className="trucash-token-main__content-info">
            <Fade right>
              <h1>TRUCASH TOKEN</h1>
              <div className="trucash-token-main__deco-bar main-color-background"></div>
              <p>
                TCH is an open source token based on the Libra blockchain
                (Facebook). The goal of Trucash cryptocurrency is to become a
                mainstream digital currency that can be used by anyone. By
                charging much cheaper fees than other money sending services,
                utilizing blockchain technology, and holding the value of the
                currency stable, Trucash wants to provide better, cheaper, and
                more open access to financial services for all. The primary
                purpose of Trucash is to be used in cross-border payments and
                money transfers.
              </p>
              <div className="trucash-token-main__button-main">
                <Pulse delay={1000}>
                  <Link
                    style={{ padding: "16px" }}
                    className="btn btn-outline-success"
                    to="pdfs/TruCash-WhiteBook(eng).pdf"
                    target="blank"
                  >
                    Download White Paper
                  </Link>
                </Pulse>
                <Pulse delay={1000}>
                  <LinkCasino href="https://tru-cash.com/" target="_blank">
                    Read More
                  </LinkCasino>
                </Pulse>
              </div>
            </Fade>
          </div>
        </div>
      </>
    );
  }
}

export default HomeTrucashToken;
