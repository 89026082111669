import React from 'react';
import './products-screen.css';

// components
import ProductsHeader from './products-components/products-header/products-header';
import ProductsOur from './products-components/products-our/products-our';
import ProductsWhyUse from './products-components/products-why-use/products-why-use';



class ProductsScreen extends React.Component{
    
    render(){
        return (<>
               <div className="sub-main-products">
                     <ProductsHeader/>
                     <ProductsOur/>
                     <ProductsWhyUse/>
               </div>
        </>);
    }
}

export default ProductsScreen;