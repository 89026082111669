import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// Components
import InfoHeader from "./components/header/info/info-header";
import NavbarHeader from "./components/header/navbar/navbar-header";
import HomeScreen from "./components/screens/home/home-screen";
import Footer from "./components/footer/footer";
import AboutUsScreen from "./components/screens/about-us/about-us-screen";
import PackagesScreen from "./components/screens/packages/packages-screen";
import ProductsScreen from "./components/screens/products/productos-screen";
import TrucashScreen from "./components/screens/trucash/trucash-screen";
import ReferralScreen from "./components/screens/referral-program/referral-program-screen";
import LeadershipProgramScreen from "./components/screens/leadership-program/leadership-program-screen";
import MembershipProgramScreen from "./components/screens/membership-program/membership-program-screen";
import ContactScreen from "./components/screens/contact/contact-screen";
import TermOfServiceScreen from "./components/screens/terms-of-service/terms-of-service-screen";
import PrivacyPolicyScreen from "./components/screens/privacy-policy/privacy-policy-screen";
import BusinessScreen from "./components/screens/business/business-screen";

function App() {
  return (
    <Router>
      <div className="main">
        <div className="main__header">
          <InfoHeader />
          <NavbarHeader />
        </div>
        <Switch>
          <Route exact path="/" component={HomeScreen}></Route>
          <Route exact path="/about" component={AboutUsScreen}></Route>
          <Route exact path="/packages" component={PackagesScreen}></Route>
          <Route exact path="/products" component={ProductsScreen}></Route>
          <Route exact path="/trucash" component={TrucashScreen}></Route>
          <Route
            exact
            path="/referral-program"
            component={ReferralScreen}
          ></Route>
          <Route
            exact
            path="/leadership-program"
            component={LeadershipProgramScreen}
          ></Route>
          <Route
            exact
            path="/membership-program"
            component={MembershipProgramScreen}
          ></Route>
          <Route exact path="/contact" component={ContactScreen}></Route>
          <Route
            exact
            path="/terms-of-service"
            component={TermOfServiceScreen}
          ></Route>
          <Route
            exact
            path="/privacy-policy"
            component={PrivacyPolicyScreen}
          ></Route>
          <Route exact path="/business" component={BusinessScreen}></Route>
        </Switch>
        <div className="footer">
          <Footer />
        </div>
      </div>
      <Redirect to="/" />
    </Router>
  );
}

export default App;
