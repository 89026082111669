import React from 'react';
import './home-services.css';
import Fade from 'react-reveal/Fade';

// assets
import service_img1 from '../../../../../assets/images/simple-images/services1.jpg'
import service_img2 from '../../../../../assets/images/simple-images/services2.jpg'

class HomeServices extends React.Component{
    
    render(){
        return (<>
            <div className="services-main">
                <h1 className="services-main__title">Services</h1>
            
                <div className="services-main__cards">
                <Fade left>
                <div className="services-main__card">
                    <img src={service_img1} alt=""/>
                    <Fade delay={300}>
                    <h2 className="main-color-text">Financial Operations</h2>
                    <p>Taurus Capital is a technology development and private investment group. Its blockchain solutions in the market can help anyone with or without experience, small and medium investors to join the field of cryptocurrencies with the highest security.</p>
                    </Fade>
                </div>
                </Fade>
                <Fade right>
                <div className="services-main__card">
                    <img src={service_img2} alt=""/>
                    <Fade delay={300}>
                    <h2 className="main-color-text">Eco-solar Mining</h2>
                    <p>The company also has contracted eco-solar cryptocurrency mining centers in Iceland and soon in Texas, USA. So far, we have a mining capacity of 100 megawhats.</p>
                    </Fade>
               </div>
                </Fade>
                
              
                </div>
            
            </div>
        </>);
    }
}

export default HomeServices;