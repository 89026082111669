import React from 'react';
import TrucashEcosystem from './trucash-components/trucash-ecosystem/trucash-ecosystem';
import TrucashHeader from './trucash-components/trucash-header/trucash-header';
import TrucashTechnical from './trucash-components/trucash-technical/trucash-technical';
import TrucashWhatIs from './trucash-components/trucash-what-is/trucash-what-is';

import './trucash-screen.css';

// components


class TrucashScreen extends React.Component{
    
    render(){
        return (<>
               <div className="sub-main-trucash">
                    <TrucashHeader/>
                    <TrucashWhatIs/>
                    <TrucashTechnical/>
                    <TrucashEcosystem/>
               </div>
        </>);
    }
}

export default TrucashScreen;