import React from 'react';
import './about-us-our-services.css';
import imgAboutUs from '../../../../../assets/images/simple-images/1-our-servis.png';
import imgAboutUs2 from '../../../../../assets/images/simple-images/2-our-servis.png';


class AboutUsOurServices extends React.Component{
    
    render(){
        return (<>
              <div className="about-us-our-services">

                       
                     

                        <div className="about-us-our-services__info">
                            <h1>OUR SERVICES</h1>
                            <div className="about-us-our-services__deco"></div>
                            <h2>Financial Operations</h2>
                            <p>Invest under the guidance of successful traders in the industry who will help you make your first investments in the financial markets.</p>
                            <p>At Taurus Capital, our professional traders await every new oppoertunity that opens up in the financial market to trade successfully for you.</p>
                            <p>We can fully boast of some of the most talented traders and educators who have a huge amount of experience in profitably trading capital over many years. We currently trade equities, Forex, options, futures and cryptocurrencies. Our professional traders are generating between 5% and 7% every week in the financial markets despite the condition of the market.</p>
                            <p>Our trading and arbitrage bots are easy to use and allows new users to ensure their trading strategies are being correctly implemented. Our bots also creates a large number of simultaneous trades by quicly taking advantage of regular market fluctuations and placing trades as fast as 3 and 4 thousandths of seconds before market movements occur.</p>
                            <p>Our trading software works with all market conditions and isn’t affected by the volatility of the market.</p>
                        </div>

                        <div className="about-us-our-services__img">
                            <img src={imgAboutUs} alt=""/>
                        </div>

              </div>

              <div className="about-us-our-services">

                       
                     
              <div className="about-us-our-services__img">
                    <img src={imgAboutUs2} alt=""/>
                </div>
                <div className="about-us-our-services__info">
                
                    <h2>Eco-Solar Crypto Mining</h2>
                    <p>One of the biggest drawbacks of mining cryptocurrency is its massive energy consumption. The mining process which is used to add blocks to the ledger involves using computer hardware to solve mathematical equations. The process is resource-intensive and not sustainable because of it effects on the climate.</p>
                    <p>Our aim is to solve the problem of energy consumption in the blockchain with a solar energy farm. Our approach is to use green energy to power the data centres where the mining takes place. We will combine renewable energy and cryptocurrency mining by using photovoltaic solar panels to fuel all our mining activities.</p>
                    <p>Our ecological mining centers works 24 hours a day and is responsible for mining the most profitable cryptocurrencies. As an investor, you can obtain these cryptocurrencies for 10%-30% below the market value while you are guaranteed of a fixed profitability for the entire year or duration of the investment.</p>
                    <br/><br/>
                </div>
                

              

                </div>
              

  

        </>);
    }
}

export default AboutUsOurServices;