import React from 'react';
import './leadership-program-header.css';
import  imgHeader from '../../../../../assets/images/simple-images/BANNERpartner.jpg';

// assets


class LeadershipProgramHeader extends React.Component{
  
    render(){
        return (<>
              <div className="leadership">
                    <img src={imgHeader} alt=""/>
              </div>
        </>);
    }
}

export default LeadershipProgramHeader;