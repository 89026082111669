import React from 'react';
import './referral-program-final-section.css';

// assets
import final from '../../../../../assets/images/simple-images/backg.jpg';



class ReferralProgramFinalSection extends React.Component{
  
    render(){
        return (<>
              <div className="referral-program-final-section">
                    <img src={final} alt=""/>
              </div>
        </>);
    }
}

export default ReferralProgramFinalSection;