import React from 'react';
import './about-us-third.css';
import mission from '../../../../../assets/images/simple-images/mision.png';
import objetives from '../../../../../assets/images/simple-images/obj.jpg';

class AboutUsThird extends React.Component{
    
    render(){
        return (<>
              <div className="about-us-third">

                       
                        <div className="about-us-third__mission">
                            <img src={mission} alt=""/>
                            <div className="about-us-third__info">
                       <h1>MISSION</h1>
                            <div className="about-us-second__deco"></div>

                            <p>To be a respected corporation. We also want to position our products and services in different markets and industries so that each of our partners around the world would constantly benefit as well as help grow our assets together.</p>
                            <p>Secondly, we want to achieve the $70 million capitalization in the first 5 years of operation.</p>
                            <p>Thirdly, that our Taurus Education Academy certifies 1,000 new professional traders, miners and Cryptocurrency experts this 2020.</p>
                            <p>Creating a cost-effective and efficient cryptocurrency mining facility with robust infrastructural backbone.</p>
                       </div>
                        </div>

                        <div className="about-us-third__objetives">
                        <img src={objetives} alt=""/>

                       <div className="about-us-third__info">
                       <h1>OBJECTIVES</h1>
                            <div className="about-us-second__deco"></div>

                            <p>Taurus Capital was created to help investors find new investment alternatives in the cypto market.</p>
                            <p>To build a cryptocurrency with real value.</p>
                            <p>To provide opportunities for profitable, reliable and innovative investment.</p>
                            <p>To help our clients achieve long-term success in reaching their goals.</p>
                       </div>
                        </div>

              </div>
        </>);
    }
}

export default AboutUsThird;