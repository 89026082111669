import React from "react";
import "./home-screen.css";

// components
import HomeHeader from "./home-components/home-header/home-header";
import HomeAboutUs from "./home-components/home-about-us/home-about-us";
import HomeServices from "./home-components/home-services/home-services";
import HomeInvestment from "./home-components/home-investment/home-investment";
import HomeEcosystem from "./home-components/home-ecosystem/home-ecosystem";
import HomeGetStartedButton from "./home-components/home-get-started-button/home-get-started-button";
import HomeTrucashToken from "./home-components/home-trucash-token/home-trucash-token";
import HomeRoadMap from "./home-components/home-road-map/home-road-map";
import HomeWhyChooseUs from "./home-components/home-why-choose-us/home-why-choose-us";
import { CasinoScreen } from "./home-components/casino/CasinoScreen";

class HomeScreen extends React.Component {
  render() {
    return (
      <>
        <div className="sub-main">
          <HomeHeader />
          <HomeAboutUs />
          <HomeServices />
          <HomeInvestment />
          <HomeEcosystem />
          <HomeGetStartedButton />
          <CasinoScreen />
          <HomeTrucashToken />
          <HomeRoadMap />
          <HomeWhyChooseUs />
          <HomeGetStartedButton align="Center" />
        </div>
      </>
    );
  }
}

export default HomeScreen;
