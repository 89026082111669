import React from 'react';
import './contact-header.css';

// assets
import  imgHeader from '../../../../../assets/images/simple-images/BANNERABSUPORT.jpg';


class ContactHeader extends React.Component{
  
    render(){
        return (<>
              <div className="contact-header">
                    <img src={imgHeader} alt=""/>
              </div>
        </>);
    }
}

export default ContactHeader;